import React from 'react';
import Loader from './Loader';
import Theme from '../../theme';

import { Page } from './styles';

const PageLoader = ({ ...rest }) => (
  <Page {...rest}>
    <Loader color={Theme.colors.brand} size="150px" />
  </Page>
);

export default PageLoader;
