import styled, { keyframes } from 'styled-components';
import Container from '../Container';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

export const Page = styled(Container)`
  text-align: center;

  > div {
    min-height: 80vh;
  }
`;

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 auto;
  width: ${(props) => props.size};

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

export const Icon = styled.svg`
  animation: ${rotate} 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const Circle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ${dash} 1.5s ease-in-out infinite;
  stroke: ${(props) => props.color};
  stroke-width: ${(props) => props.stroke};
  transition: stroke 0.2s ease;
`;
