import React from 'react';
import { Wrapper, Icon, Circle } from './styles';

const Loader = ({ size = '400px', color = 'white', stroke = '2', ...rest }) => (
  <Wrapper size={size} {...rest}>
    <Icon viewBox="25 25 50 50">
      <Circle
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth={stroke}
        stroke={stroke}
        strokeMiterlimit="10"
        color={color}
      />
    </Icon>
  </Wrapper>
);

export default Loader;
